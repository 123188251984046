import React from "react";
import HeaderMenuLite from "../partials/HeaderMenuLite";
import Footer from "../partials/Footer";
import { termdata } from "./Term-data";
import "./term.css";
import { Link } from "react-scroll";

export default function Termcondition2() {
    return (
        <>
            <div className="terms color-tnc-indigo" style={{ 'transition': 'background-color 1s ease' }} data-color="indigo">
                <div className="wrapper-lite ">
                    <HeaderMenuLite />
                    <div className="smart_toolsets_trem" >
                        <div className="banner_section">
                          
                        </div>
                        <div className="py-5 term-color-padding-change">
                            <div className="" id="smart-toolsets" data-color="indigo" >
                                <div className="term-condition-page">
                                    <div className="container">
                                        <div className="row mt-1">
                                        <h1 className="faq-tnc-heading d-block d-lg-none mb-5">Terms and Conditions</h1>
                                            <div className="col-lg-4">
                                            <div className="mobile-policy-sticky d-block d-lg-none">
                                                 <div class="dropdown dropright mobile-menu-policy">
                                                     <button class="btn btn-tpt dropdown-toggle p-0" type="button" data-bs-toggle="dropdown">Category
                                                         <span class="caret"></span></button>
                                                     <div class="dropdown-menu" role="menu">
                                                     <ol className="list-group  term-condition-left animated-line">
                                                    <li className="list-group-item ">
                                                        <Link activeClassName="active" smooth spy to="section_0" offset={-200}>
                                                            <span>1. Definition</span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item ">
                                                        <Link activeClassName="active" smooth spy to="section_1" offset={-200}>
                                                            <span>2. Conducting Business with PayStreet</span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <Link activeClassName="active" smooth spy to="section_2" offset={-200}>
                                                            <span>3. Settlement</span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <Link activeClassName="active" smooth spy to="section_3" offset={-200}>
                                                            <span>4. Funding Balance</span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <Link activeClassName="active" smooth spy to="section_4" offset={-200}>
                                                            <span>5. Incoming Payments and returned cheques</span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <Link activeClassName="active" smooth spy to="section_5" offset={-200}>
                                                            <span>6. Errors and Discrepancies</span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <Link activeClassName="active" smooth spy to="section_6" offset={-200}>
                                                            <span>7. Online System</span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <Link activeClassName="active" smooth spy to="section_7" offset={-200}>
                                                            <span>8. Online System Security</span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <Link activeClassName="active" smooth spy to="section_8" offset={-200}>
                                                            <span>9. Intellectual property</span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <Link activeClassName="active" smooth spy to="section_9" offset={-200}>
                                                            <span>10. Force Majeure</span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <Link activeClassName="active" smooth spy to="section_10" offset={-200}>
                                                            <span>11. Term, suspension and Termination</span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <Link activeClassName="active" smooth spy to="section_11" offset={-200}>
                                                            <span>12. Representations and warranties</span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <Link activeClassName="active" smooth spy to="section_12" offset={-200}>
                                                            <span>13. Legal and regulatory compliance</span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <Link activeClassName="active" smooth spy to="section_13" offset={-200}>
                                                            <span>14. Confidential Information</span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <Link activeClassName="active" smooth spy to="section_14" offset={-200}>
                                                            <span>15.  Use of information, privacy and disclosure</span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <Link activeClassName="active" smooth spy to="section_15" offset={-200}>
                                                            <span>16. Limitation of Liability; Indemnity</span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <Link activeClassName="active" smooth spy to="section_16" offset={-200}>
                                                            <span>17. Communication and notices </span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <Link activeClassName="active" smooth spy to="section_17" offset={-200}>
                                                            <span>18. Dispute resolution</span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <Link activeClassName="active" smooth spy to="section_18" offset={-200}>
                                                            <span>19.  General terms and conditions </span>
                                                        </Link>
                                                    </li>
                                                </ol>
                                                     </div>
                                                 </div>
                                                
                                            </div>
                                                <ol className="list-group  term-condition-left animated-line d-none d-lg-flex">
                                                    <li className="list-group-item ">
                                                        <Link activeClassName="active" smooth spy to="section_0" offset={-200}>
                                                            <span>1. Definition</span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item ">
                                                        <Link activeClassName="active" smooth spy to="section_1" offset={-200}>
                                                            <span>2. Conducting Business with PayStreet</span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <Link activeClassName="active" smooth spy to="section_2" offset={-200}>
                                                            <span>3. Settlement</span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <Link activeClassName="active" smooth spy to="section_3" offset={-200}>
                                                            <span>4. Funding Balance</span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <Link activeClassName="active" smooth spy to="section_4" offset={-200}>
                                                            <span>5. Incoming Payments and returned cheques</span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <Link activeClassName="active" smooth spy to="section_5" offset={-200}>
                                                            <span>6. Errors and Discrepancies</span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <Link activeClassName="active" smooth spy to="section_6" offset={-200}>
                                                            <span>7. Online System</span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <Link activeClassName="active" smooth spy to="section_7" offset={-200}>
                                                            <span>8. Online System Security</span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <Link activeClassName="active" smooth spy to="section_8" offset={-200}>
                                                            <span>9. Intellectual property</span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <Link activeClassName="active" smooth spy to="section_9" offset={-200}>
                                                            <span>10. Force Majeure</span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <Link activeClassName="active" smooth spy to="section_10" offset={-200}>
                                                            <span>11. Term, suspension and Termination</span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <Link activeClassName="active" smooth spy to="section_11" offset={-200}>
                                                            <span>12. Representations and warranties</span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <Link activeClassName="active" smooth spy to="section_12" offset={-200}>
                                                            <span>13. Legal and regulatory compliance</span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <Link activeClassName="active" smooth spy to="section_13" offset={-200}>
                                                            <span>14. Confidential Information</span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <Link activeClassName="active" smooth spy to="section_14" offset={-200}>
                                                            <span>15.  Use of information, privacy and disclosure</span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <Link activeClassName="active" smooth spy to="section_15" offset={-200}>
                                                            <span>16. Limitation of Liability; Indemnity</span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <Link activeClassName="active" smooth spy to="section_16" offset={-200}>
                                                            <span>17. Communication and notices </span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <Link activeClassName="active" smooth spy to="section_17" offset={-200}>
                                                            <span>18. Dispute resolution</span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <Link activeClassName="active" smooth spy to="section_18" offset={-200}>
                                                            <span>19.  General terms and conditions </span>
                                                        </Link>
                                                    </li>
                                                </ol>
                                            </div>
                                            <div className="col-lg-7 offset-lg-1 term-content">
                                            <h1 className="faq-tnc-heading d-none d-lg-block">Terms and Conditions</h1>
                                            <p class="tnc_heading">Preamble</p>
                                           <div className="tnc-runing-text">
                                             <p>The entire terms and conditions must be read in conjunction with the following disclaimer and notification. </p><p>Payment Services in Singapore are regulated under the Payment Services Act 2019 (the "PSA"). PayStreet Pte. Ltd. ("PayStreet") is a financial technology company, not a bank, and is acting as an agent of Licensed Entity.  Inward and outward remittance on PayStreet platform is powered by Wallex, a business unit of M-DAQ PAYMENT SOLUTIONS PTE. LTD.</p><p>PayStreet is not licensed, nor exempt to provide cross-border money transfer services, and consequently collaborates with partners licensed by Monetary Authority of Singapore (MAS) to offer these services. Wallex under the aegis of M-DAQ PAYMENT SOLUTIONS PTE. LTD. is regulated by the MAS and is a licensed Major Payment Institution under the PSA 2019 for the provision of Cross-border Money Transfer Services in Singapore (License No. PS20200433). Funds received by Wallex that constitute "relevant moneys" (as defined under the PSA) are held on the customer’s behalf in a trust account opened with a Safeguarding Institution as required under the PSA.</p>
                                           </div>
                                                {/* <p className="tc-heading-small-text mb-2">Last updated</p>
                                                <h3>January 2, 2023</h3> */}
                                                {termdata.map((item, index) => (
                                                    <section id={'section_' + index} className="mt-4">
                                                        {/* <h3 className="scroll-heading">{item.heading}</h3> */}
                                                        <div className="nav__container">
                                                            <div className="section-content">
                                                                {/* {index +1+'.'}  */}
                                                                <p className="tnc_heading" >{index+1}. {item.heading}</p>
                                                                <div dangerouslySetInnerHTML={{ __html: item.content }} className="tnc-runing-text" ></div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                ))}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bg" >
                    <Footer />
                </div>
            </div>
        </>
    );
}