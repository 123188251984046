import React from "react";
import { ADDRESS, PHONE, EMAIL } from '../../constant';

function Footer() {
    return (
        <section className="footer-overlay">
            <footer className="text-lg-start text-white" id="applyNowBtn">
                <div className="container pb-0">
                    <section className="">
                        <div className="row footer_content">
                            <div className="footer-mobile-menu d-xl-none d-block">

                                <div className="row">
                                    <hr />
                                    <div className="col-6">

                                        <h5>Products</h5>
                                        <ul className="list-unstyled mb-0">
                                            <li>
                                                <a href="/b2b" className="text-white">
                                                    Payments  platform
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/incorporation" className="text-white">
                                                    Corporate support
                                                </a>
                                            </li>
                                        </ul>

                                    </div>
                                    <div className="col-6">
                                        <h5>Solutions</h5>
                                        <ul className="list-unstyled mb-0">
                                            <li>
                                                <a href="/sendmoney" className="text-white">Send</a>
                                            </li>
                                            <li>
                                                <a href="/collect" className="text-white">Collect</a>
                                            </li>
                                            <li>
                                                <a href="/convert" className="text-white">Convert</a>
                                            </li>
                                            <li>
                                                <a href="/store" className="text-white">Store</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-6">
                                        <h5>Company</h5>
                                        <ul className="list-unstyled mb-0">
                                            <li>
                                                <a href="/about-us" className="text-white">About PayStreet</a>
                                            </li>
                                            <li>
                                                <a href="/about-us#mission" className="text-white">Mission</a>
                                            </li>
                                            <li>
                                                <a href="/about-us#why-us-section" className="text-white">Why us?</a>
                                            </li>
                                            <li>
                                                <a href="/about-us#ethos-scroll-section" className="text-white">Ethos</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-6">
                                        <h5>Resources</h5>
                                        <ul className="list-unstyled mb-0">
                                            <li>
                                                <a href="/blog" className="text-white">Blog and News</a>
                                            </li>
                                            <li>
                                                <a href="/faq" className="text-white">FAQs</a>
                                            </li>
                                            <li>
                                                <a href="#!" className="text-white">Guide</a>
                                            </li>
                                            <li>
                                                <a href="/contact" className="text-white">Contact us</a>
                                            </li>

                                        </ul>
                                    </div>
                                    <div className="col-12 col-md-6 brand-img">
                                        <h5>
                                            <a className="navbar-brand mb-0" href="#!">
                                                <img src="/images/logo.png" alt="..." style={{ width: '180px' }} />
                                            </a>
                                        </h5>
                                        <ul className="list-unstyled mb-0 pe-0">
                                            <li className="d-flex align-items-start">
                                                <img src="/images/location.png" className="mr_20" alt=".." />
                                                <a href="#!">Paystreet Pte. Ltd.   <br />111 North Bridge Road <br /> #24-02 Peninsula Plaza <br />Singapore (179098)</a>
                                            </li>
                                            <li className="d-flex align-items-start">
                                            <img src="/images/location.png" className="mr_20" alt=".." />
                                            <a href="#!">
                                            Paystreet Limited<br></br>
                                                Unit 6, 12/F, Infinitus Plaza<br />
                                                199 Des Voeux Road Central <br />
                                                Sheung Wan, Hong Kong
                                            </a>
                                        </li>
                                            <li>
                                                <img src="/images/mail.png" className="mr_20" alt="..." />
                                                <a className="text-decoration"
                                                    href="mailto:contact@paystreet.io">{EMAIL}</a>
                                            </li>
                                            {/* <li>
                                            <img src="/images/call.png" className="mr_20" alt="..." />
                                            <a className="mb_30" href="tel:123-456-7890">{PHONE}</a>
                                        </li> */}
                                            <li className="mt-5  sociaL_MEDIA">
                                                <a href="https://www.linkedin.com/company/paystreet.io/about/" target="_blank" role="button">
                                                    <i className="fab fa-linkedin-in" />
                                                </a>
                                                <a href="https://www.facebook.com/paystreet.io" target="_blank" role="button">
                                                    <i className="fab fa-facebook-f" />
                                                </a>
                                                <a href="https://twitter.com/pay_street" target="_blank" role="button">
                                                    <i className="fab fa-twitter" />
                                                </a>
                                                <a href="https://www.instagram.com/paystreet.io/" target="_blank" role="button">
                                                    <i className="fab fa-instagram" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <h5>Coming soon</h5>
                                        <ul className="list-unstyled mb-0 image_link">
                                            <li>
                                                <a href="#!" className="text-white">
                                                    <img src="images/google_play.svg" alt="..." />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#!" className="text-white">
                                                    <img src="images/app_store.svg" alt="..." />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <ul className="d-flex  justify-content-between list-unstyled mb-2 d-none d-xl-flex">

                                <li className="first_column">
                                    <h5>
                                        <a className="navbar-brand mb-0" href="#!">
                                            <img src="/images/logo.png" alt="..." style={{ width: '180px' }} />
                                        </a>
                                    </h5>
                                    <ul className="list-unstyled mb-0 pe-0">
                                        <li className="d-flex align-items-start">
                                            <img src="/images/location.png" className="mr_20" alt=".." />
                                            <a href="#!">Paystreet Pte. Ltd.   <br />111 North Bridge Road <br className="d-none d-lg-block" /> #24-02 Peninsula Plaza <br className="d-none d-lg-block" />Singapore (179098)</a>
                                        </li>
                                        <li className="d-flex align-items-start">
                                            <img src="/images/location.png" className="mr_20" alt=".." />
                                            <a href="#!">
                                            Paystreet Limited<br></br>
                                                Unit 6, 12/F, Infinitus Plaza<br />
                                                199 Des Voeux Road Central<br />
                                                Sheung Wan, Hong Kong
                                            </a>
                                        </li>
                                        <li>
                                            <img src="/images/mail.png" className="mr_20" alt="..." />
                                            <a className="text-decoration"
                                                href="mailto:contact@paystreet.io">{EMAIL}</a>
                                        </li>
                                        {/* <li>
                                            <img src="/images/call.png" className="mr_20" alt="..." />
                                            <a className="mb_30" href="tel:123-456-7890">{PHONE}</a>
                                        </li> */}
                                        <li className="mt-5  sociaL_MEDIA">
                                            <a href="https://www.linkedin.com/company/paystreet.io/about/" target="_blank" role="button">
                                                <i className="fab fa-linkedin-in" />
                                            </a>
                                            <a href="https://www.facebook.com/paystreet.io" target="_blank" role="button">
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                            <a href="https://twitter.com/pay_street" target="_blank" role="button">
                                                <i className="fab fa-twitter" />
                                            </a>
                                            <a href="https://www.instagram.com/paystreet.io/" target="_blank" role="button">
                                                <i className="fab fa-instagram" />
                                            </a>

                                        </li>
                                    </ul>
                                </li>
                                <li className="fourth_column d-none d-xl-block">
                                    <h5>Products</h5>
                                    <ul className="list-unstyled mb-0">
                                        <li>
                                            <a href="/b2b" className="text-white">Payments
                                                platform</a>
                                        </li>
                                        <li>
                                            <a href="/incorporation" className="text-white">Corporate support</a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="third_column d-none d-xl-block">
                                    <h5>Solutions</h5>
                                    <ul className="list-unstyled mb-0">
                                        <li>
                                            <a href="/sendmoney" className="text-white">Send</a>
                                        </li>
                                        <li>
                                            <a href="/collect" className="text-white">Collect</a>
                                        </li>
                                        <li>
                                            <a href="/convert" className="text-white">Convert</a>
                                        </li>
                                        <li>
                                            <a href="/store" className="text-white">Store</a>
                                        </li>

                                    </ul>
                                </li>
                                <li className="secound_column d-none d-xl-block">
                                    <h5>Company</h5>
                                    <ul className="list-unstyled mb-0">
                                        <li>
                                            <a href="/about-us" className="text-white">About PayStreet</a>
                                        </li>
                                        <li>
                                            <a href="/about-us#mission" className="text-white">Mission</a>
                                        </li>
                                        <li>
                                            <a href="/about-us#why-us-section" className="text-white">Why us?</a>
                                        </li>
                                        <li>
                                            <a href="/about-us#ethos-scroll-section" className="text-white">Ethos</a>
                                        </li>
                                    </ul>
                                </li>

                                <li className="fifth_column d-none d-xl-block">
                                    <h5>Resources</h5>
                                    <ul className="list-unstyled mb-0">
                                        <li>
                                            <a href="/blog" className="text-white">Blog and News</a>
                                        </li>
                                        <li>
                                            <a href="/faq" className="text-white">FAQs</a>
                                        </li>
                                        <li>
                                            <a href="#!" className="text-white">Guide</a>
                                        </li>
                                        <li>
                                            <a href="/contact" className="text-white">Contact us</a>
                                        </li>

                                    </ul>
                                </li>
                                <li className="sixth_column">
                                    <h5>Coming soon</h5>
                                    <ul className="list-unstyled mb-0 image_link">
                                        <li>
                                            <a href="#!" className="text-white">
                                                <img src="images/google_play.svg" alt="..." />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#!" className="text-white">
                                                <img src="images/app_store.svg" alt="..." />
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </section>
                    {/* <hr className="mb-0"/> */}
                    <section className="p_37" id="disclosure">
                        <p className="mb-0  justify-content-center align-items-center mb_30">
                            Customer notification - Payment Services in Singapore are regulated under the Payment Services Act 2019 (the "PSA"). PayStreet Pte. Ltd. ("PayStreet") is a financial technology company, not a bank. Inward and outward remittance on PayStreet platform is powered by Wallex a business unit of M-DAQ PAYMENT SOLUTIONS PTE. LTD.<br></br><br></br>
                            PayStreet is not licensed, nor exempt to provide cross-border money transfer services, and consequently collaborates with partners licensed by Monetary Authority of Singapore (MAS) to offer these services. Wallex under the aegis of M-DAQ PAYMENT SOLUTIONS PTE. LTD. is regulated by the MAS and is a licensed Major Payment Institution under the PSA 2019 for the provision of Cross-border Money Transfer Services in Singapore (License No. PS20200433). Funds received by Wallex that constitute "relevant moneys" (as defined under the PSA) are held on the customer’s behalf in a trust account opened with a Safeguarding Institution as required under the PSA.

                        </p><br />
                        <p>For any inquiries regarding data protection, kindly reach out to our Data Protection Officer at <a href="Mailto:dpo@paystreet.io" className="text-decoration mb-0">dpo@paystreet.io</a>.
                        </p>
                    </section>
                </div>
            </footer>
            <hr className="mb-0 mt-0" />
            <div className="container copy_write d-none d-lg-block d-md-block">
                <span className="text-left">
                    Copyright © 2025 | <a className="text-white mb-0" href="#">www.paystreet.io</a>
                </span>
                <span className="text-right">
                    <span><a href="/sitemap" className="footer-policy">Sitemap </a></span>|<span>  <a href="/privacy-policy" className="footer-policy">Privacy Policy </a></span>|<span> <a href="/termandconditions" className="footer-policy">Terms and Conditions</a></span>
                </span>
            </div>
            <div className="container copy_write d-block d-lg-none d-md-none">

                <div className="text-right mb-2">
                    <span><a href="/sitemap" className="footer-policy">Sitemap </a></span>|<span>  <a href="/privacy-policy" className="footer-policy">Privacy Policy </a></span>|<span> <a href="/termandconditions" className="footer-policy">Terms and Conditions</a></span>
                </div>
                <div className="text-left">
                    Copyright © 2025 | <a className="text-white mb-0" href="#">www.paystreet.io</a>
                </div>
            </div>
        </section>
    );
}

export default Footer;