import React, { useEffect, useState } from "react";
import HeaderMenu from "../partials/HeaderMenu";
import Footer from "../partials/Footer";
import {
  URL,
  REGISTERURL,
  COUNTRIES,
  COMPANYTYPE,
  INDUSTRIES,
  MAIL_URL,
  SubscribtionMailurl,
} from "../../constant";
import { Link } from "react-scroll";
import axios from "axios";
import PageLoading from "../components/loading/page-loading";

function Contact() {
  // const [firstName, setFirstName] = useState("");
  // const [lastName, setLastName] = useState("");
  // const [email, setEmail] = useState("");
  // const [contactNumber, setContactNumber] = useState("");
  // const [company, setCompany] = useState("");
  // const [message, setMessage] = useState("");
  // const [country, setCountry] = useState("");
  // const [companyType, setCompanyType] = useState("");
  // const [industry, setIndustry] = useState("");
  const [btnsubmit, setBtnsubmit] = useState(true);
  const [successmsg, setSuccessmsg] = useState(false);

  const [inputValues, setInputValue] = useState({
    fName: "",
    lName: "",
    email: "",
    company: "",
    country: "",
    companyType: "",
    industry: "",
    message: "",
    mobile: "",
  });

  const [validation, setValidation] = useState({
    fName: { touched: false, error: "" },
    lName: { touched: false, error: "" },
    email: { touched: false, error: "" },
    company: { touched: false, error: "" },
    country: { touched: false, error: "" },
    companyType: { touched: false, error: "" },
    industry: { touched: false, error: "" },
    message: { touched: false, error: "" },
    mobile: { touched: false, error: "" },
  });
  const [valid, setValid] = useState(false);
  //handle submit updates
  // function handleChange(event) {
  //   const { name, value } = event.target;
  //   setInputValue({ ...inputValues, [name]: value });
  // }
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputValue({ ...inputValues, [name]: value });
    setValidation({
      ...validation,
      [name]: { ...validation[name], touched: true },
    });
  };

  const checkValidation = (props) => {
    let errors = { ...validation };
    if (errors.fName.touched || props) {
      if (!inputValues.fName) {
        errors.fName.error = "First name is required";
        setValid(false);
      } else {
        errors.fName.error = "";
        setValid(true);
      }
    }

    if (errors.lName.touched || props) {
      if (!inputValues.lName) {
        errors.lName.error = "Last name is required";
        setValid(false);
      } else {
        errors.lName.error = "";
        setValid(true);
      }
    }

    if (errors.company.touched || props) {
      if (!inputValues.company) {
        errors.company.error = "Company name is required";
        setValid(false);
      } else {
        errors.company.error = "";
        setValid(true);
      }
    }

    if (errors.country.touched || props) {
      if (!inputValues.country) {
        errors.country.error = "Country name is required";
        setValid(false);
      } else {
        errors.country.error = "";
        setValid(true);
      }
    }

    if (errors.email.touched || props) {
      const emailCond =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!inputValues.email) {
        errors.email.error = "Email is required";
        setValid(false);
      } else if (!emailCond.test(inputValues.email)) {
        errors.email.error = "Please enter a valid email address";
        setValid(false);
      } else {
        errors.email.error = "";
        setValid(true);
      }
    }

    if (errors.mobile.touched || props) {
      const mobileCond = /^\+?\d+$/;
      if (inputValues.mobile.length === 0) {
        errors.mobile.error = "";
        setValid(true);
      } else if (!mobileCond.test(inputValues.mobile)) {
        errors.mobile.error = "Enter a valid number";
        setValid(false);
      } else if (inputValues.mobile.length < 10) {
        errors.mobile.error = "Please enter exactly 10 digits";
        setValid(false);
      } else {
        errors.mobile.error = "";
        setValid(true);
      }
    }

    if (errors.companyType.touched || props) {
      if (!inputValues.companyType) {
        errors.companyType.error = "Company type is required";
        setValid(false);
      } else {
        errors.companyType.error = "";
        setValid(true);
      }
    }

    if (errors.industry.touched || props) {
      if (!inputValues.industry) {
        errors.industry.error = "Industry name is required";
        setValid(false);
      } else {
        errors.industry.error = "";
        setValid(true);
      }
    }

    if (errors.message.touched || props) {
      if (!inputValues.message) {
        errors.message.error = "Message is required";
        setValid(false);
      } else {
        errors.message.error = "";
        setValid(true);
      }
    }

    setValidation(errors);
    // setBtnSubmit(Object.values(errors).some((error) => error.error !== ""));
    if (
      errors.fName.error !== "" ||
      errors.lName.error !== "" ||
      errors.company.error !== "" ||
      errors.message.error !== "" ||
      errors.email.error !== "" ||
      errors.industry.error !== "" ||
      errors.companyType.error !== "" ||
      errors.mobile.error !== "" ||
      errors.country.error !== ""
    ) {
      setBtnsubmit(true);
    } else {
      setBtnsubmit(false);
    }

    if (
      inputValues.fName &&
      inputValues.lName &&
      inputValues.message &&
      inputValues.companyType &&
      inputValues.company &&
      inputValues.email &&
      inputValues.industry &&
      inputValues.country
    ) {
      setValid(true);
    }
  };

  useEffect(() => {
    checkValidation();
  }, [inputValues]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Mark all fields as touched
    setSuccessmsg(false);
    const check =
      inputValues.fName === "" ||
      inputValues.lName === "" ||
      inputValues.company === "" ||
      inputValues.country === "" ||
      inputValues.companyType === "" ||
      inputValues.industry === "" ||
      inputValues.email === "" ||
      inputValues.message === "";
    if (valid && !check) {
      //   alert("Gfdg");
      let data = JSON.stringify({
        firstName: inputValues.fName,
        lastName: inputValues.lName,
        companyName: inputValues.company,
        countryOfIncorporation: inputValues.country,
        companyType: inputValues.companyType,
        industry: inputValues.industry,
        emailAddress: inputValues.email,
        message: inputValues.message,
        mobileNumber: inputValues.mobile,
      });
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: MAIL_URL,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          setBtnsubmit(true);
          setSuccessmsg(true);
          setInputValue({
            fName: "",
            lName: "",
            email: "",
            company: "",
            country: "",
            companyType: "",
            industry: "",
            message: "",
            mobile: "",
          });
          setValidation({
            fName: { touched: false, error: "" },
            lName: { touched: false, error: "" },
            email: { touched: false, error: "" },
            company: { touched: false, error: "" },
            country: { touched: false, error: "" },
            companyType: { touched: false, error: "" },
            industry: { touched: false, error: "" },
            message: { touched: false, error: "" },
            mobile: { touched: false, error: "" },
          });
          setValid(false);
        })
        .catch((error) => {
          console.log(error);
          setValid(false);
        });
    } else {
      setTimeout(() => {
        checkValidation(true);
      }, 10);
      setValid(false);
    }
  };

  //   const handleSignup = () => {
  //     window.location.href = REGISTERURL;
  //   };

  const renderCountryOptions = () => {
    return COUNTRIES.map((country, k) => {
      return (
        <option value={country.name} key={`country${k}`}>
          {country.name}
        </option>
      );
    });
  };

  const renderCompanyTypeOptions = () => {
    return COMPANYTYPE.map((company, k) => {
      return (
        <option value={company.value} key={`company${k}`}>
          {company.value}
        </option>
      );
    });
  };

  const renderIndustryOptions = () => {
    return INDUSTRIES.map((industry, k) => {
      return (
        <option value={industry.name} key={`industry${k}`}>
          {industry.name}
        </option>
      );
    });
  };

  // subscribtionMail section
  const [subscribtionMail, setSubscribtionMail] = useState({
    emailAddress: "",
  });

  const [apiScsMessage, setApiScsMessage] = useState("");
  const [apiErrMessage, setApiErrMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const SubscribtionEmailHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setSubscribtionMail({ ...subscribtionMail, [name]: value });
  };

  const SubscribtionSubmitEmailHandler = () => {
    try {
      setApiScsMessage("");
      setApiErrMessage("");
      if (emailRegex.test(subscribtionMail.emailAddress)) {
        setLoading(true);
        axios
          .post(SubscribtionMailurl, subscribtionMail)
          .then((res) => {
            if (res.data.code === 200) {
              setApiScsMessage(res.data.message);
              setLoading(false);
              setSubscribtionMail({
                emailAddress: "",
              });
            } else {
              setApiErrMessage(res.data.message);
              setLoading(false);
              setSubscribtionMail({
                emailAddress: "",
              });
            }
          })
          .catch((err) => {
            setLoading(false);
            setApiErrMessage(err.response.data.message);
          });
      } else {
        setApiErrMessage("Enter a valid email");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div
        className="wrapper contact_page color_change"
        data-color="black"
        style={{ transition: "background-color 1s ease" }}
      >
        <div className="background_shadow">
          <HeaderMenu />
          <section className=" section_spacing background_img background_shadow">
            <div className="subpage_banner_section banner_section ">
              <p className="text-lightgeeen banner_p mt-80 l-space-2 ">
                Contact Us
              </p>
              <h1 className="m-auto Banner_title banner_h1">Get in touch</h1>
              <h3 className="text-center text-white banner_h3 mb-100">
                We're here to help, let us know.
              </h3>
              <div className="container">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="CP-banner-box">
                      <p className="text-start">New to PayStreet?</p>
                      <p className="text-start">
                        We are here to help with any questions. Let us know and
                        our team will be in touch soon.
                      </p>
                      <Link
                        activeClassName="active"
                        smooth
                        spy
                        to="contact-form-section"
                        offset={-200}
                        className="btn"
                      >
                        Contact us <i className="fal fa-long-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="CP-banner-box">
                      <p className="text-start">Already have an account?</p>
                      <p className="text-start">
                        For account specific support, existing users please
                        login and raise an enquiry from the help section.
                      </p>
                      <a href={URL} className="btn">
                        Sign in <i className="fal fa-long-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="CP-banner-box">
                      <p className="text-start">Want to open an account?</p>
                      <p className="text-start">
                        Why wait when you can do so much with PayStreet's
                        business account. Apply for free now!
                      </p>
                      <a href={REGISTERURL} className="btn">
                        Get started <i className="fal fa-long-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <section className="IP-page" id="contact-form-section">
          <div className="container mt-146">
            <h2 className="text-white  page_sub_title">Contact us</h2>
            <p className="text-start ">
              We are here to assist with inquiries, feedback, or concerns.
            </p>
            <div className="row gx-78">
              <div className="col-xl-7">
                <div className="CU-information">
                  <form onSubmit={handleSubmit}>
                    <div className="row g-20-40">
                      <div className="col-lg-6">
                        <input
                          type="text"
                          placeholder="First name"
                          className="contact_form"
                          name="fName"
                          id="fName"
                          value={inputValues.fName}
                          onChange={(e) => handleChange(e)}
                          autoComplete="off"
                        />
                        {validation.fName.error && (
                          <p className="text-danger">
                            {validation.fName.error}
                          </p>
                        )}
                      </div>
                      <div className="col-lg-6">
                        <input
                          type="text"
                          name="lName"
                          id="lName"
                          placeholder="Last name"
                          className="contact_form"
                          value={inputValues.lName}
                          onChange={(e) => handleChange(e)}
                          autoComplete="off"
                        />
                        {validation.lName.error && (
                          <p className="text-danger">
                            {validation.lName.error}
                          </p>
                        )}
                      </div>
                      <div className="col-lg-6">
                        <input
                          type="text"
                          name="company"
                          id="company"
                          placeholder="Company name"
                          className="contact_form"
                          value={inputValues.company}
                          onChange={(e) => handleChange(e)}
                          autoComplete="off"
                        />
                        {validation.company.error && (
                          <p className="text-danger">
                            {validation.company.error}
                          </p>
                        )}
                      </div>
                      <div className="col-lg-6">
                        <input
                          className="form-control contact_form"
                          list="datalistCountryOptions"
                          placeholder="Country of incorporation"
                          autoComplete="off"
                          name="country"
                          id="country"
                          value={inputValues.country}
                          onChange={(e) => handleChange(e)}
                        />
                        {validation.country.error && (
                          <p className="text-danger">
                            {validation.country.error}
                          </p>
                        )}
                        <datalist id="datalistCountryOptions">
                          <select>{renderCountryOptions()}</select>
                        </datalist>
                        {/* <select className="form-select contact_select selectpicker" required aria-label="Default select Country of incorporation" data-live-search="true">
												<option value="">Country of incorporation</option>
												{renderCountryOptions()}
											</select> */}
                      </div>
                      <div className="col-lg-6">
                        <input
                          className="form-control contact_form"
                          list="datalistCompanyOptions"
                          placeholder="Company type"
                          autoComplete="off"
                          name="companyType"
                          value={inputValues.companyType}
                          onChange={(e) => handleChange(e)}
                        />
                        {validation.companyType.error && (
                          <p className="text-danger">
                            {validation.companyType.error}
                          </p>
                        )}
                        <datalist id="datalistCompanyOptions">
                          <select>{renderCompanyTypeOptions()}</select>
                        </datalist>
                        {/* <select className="form-select contact_select selectpicker" required aria-label="Default select Company type" data-live-search="true">
												<option value="">Company type</option>
												{renderCompanyTypeOptions()}
											</select> */}
                      </div>
                      <div className="col-lg-6">
                        <input
                          className="form-control contact_form"
                          list="datalistIndustryOptions"
                          placeholder="Industry"
                          autoComplete="off"
                          name="industry"
                          value={inputValues.industry}
                          onChange={(e) => handleChange(e)}
                        />
                        {validation.industry.error && (
                          <p className="text-danger">
                            {validation.industry.error}
                          </p>
                        )}
                        <datalist id="datalistIndustryOptions">
                          <select>{renderIndustryOptions()}</select>
                        </datalist>
                        {/* <select className="form-select contact_select selectpicker" required aria-label="Default select Industry" data-live-search="true">
												<option value="">Industry</option>
												{renderIndustryOptions()}
											</select> */}
                      </div>
                      <div className="col-lg-6">
                        <input
                          type="email"
                          placeholder="Email address"
                          className="contact_form"
                          name="email"
                          id="email"
                          value={inputValues.email}
                          onChange={(e) => handleChange(e)}
                          autoComplete="off"
                        />
                        {validation.email.error && (
                          <p className="text-danger">
                            {validation.email.error}
                          </p>
                        )}
                      </div>
                      <div className="col-lg-6">
                        <input
                          type="text"
                          placeholder="Work phone number (optional)"
                          className="contact_form"
                          name="mobile"
                          minLength={10}
                          maxLength={13}
                          value={inputValues.mobile}
                          onChange={(e) => handleChange(e)}
                          autoComplete="off"
                        />
                        {validation.mobile.error && (
                          <p className="text-danger">
                            {validation.mobile.error}
                          </p>
                        )}
                      </div>
                      <div className="col-lg-12">
                        <div className="form-floating">
                          <textarea
                            className="form-control contact_form contact-placeholder"
                            placeholder="Your message"
                            id="floatingTextarea2"
                            name="message"
                            value={inputValues.message}
                            onChange={(e) => handleChange(e)}
                            // value={message}
                            // onChange={handleMessageChange}
                            autoComplete="off"
                          />
                          {validation.message.error && (
                            <p className="text-danger">
                              {validation.message.error}
                            </p>
                          )}
                        </div>
                      </div>
                      {successmsg == true && (
                        <div className="text-white">
                          Send Email successfully
                        </div>
                      )}
                      <div className="col-lg-12">
                        {btnsubmit == true && (
                          <input
                            className="btn contect-submit-btn ms-auto"
                            type="submit"
                            disabled
                          />
                        )}
                        {btnsubmit == false && (
                          <input
                            className="btn contect-submit-btn ms-auto"
                            type="submit"
                          />
                        )}
                      </div>
                    </div>
                  </form>

                </div>
              </div>

              <div className="col-xl-5">
                <div className="Blog-box">
                  <div className="CP-contact-box">
                    <p className="text-start">Blog and News</p>
                    <p className="text-start">
                      Stay on top of latest trends in digital payments,
                      PayStreet updates, media resources and more.
                    </p>
                    <a href="/blog">Take a tour </a>
                  </div>

                  <div className="CP-contact-box2">
                    <p className="text-start">Frequently asked questions</p>
                    <p className="text-start">
                      Want to learn more about PayStreet? Check this guide to
                      discover our all-in-one finance platform.
                    </p>
                    <span className="explore_link">
                      <a href={"/faq"}>Explore </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row contact-timeline">
              
            <h2 className="text-white  page_sub_title">Grievance Redressal</h2>
                <p className="text-start ">
                  Your satisfaction is our priority. 
                  To ensure your concerns are addressed promptly and effectively, please follow our structured process:
                </p>
                <div className="">
              <div className="col collect-money-timeline ">
                <ul className="timeline ">
                  <li className="mt-5">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="timeline-left-padding">
                          <h1 className="text-lightgeeen text-white">01</h1>
                          <h5 className="text-white">Submission</h5>
                          <p className="text-left">Submit your complaint or feedback through email to us directly at <a href="mailto:help@paystreet.io">help@paystreet.io</a>. Please include all the relevant details.
                          </p>
                        </div>
                      </div>

                    </div>
                  </li>
                  <li className="mt-5">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="timeline-left-padding">
                          <h1 className="text-lightgeeen text-white">02</h1>
                          <h5 className="text-white"> Acknowledgement
                          </h5>
                          <p> We will acknowledge the receipt of your email within <strong>24 hours</strong>.
                          </p>
                        </div>
                      </div>

                    </div>
                  </li>
                  <li className="mt-5">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="timeline-left-padding">
                          <h1 className="text-lightgeeen text-white">03</h1>
                          <h5 className="text-white">Investigation & Resolution</h5>
                          <p>Our team will review your concern and provide a response or resolution within <strong>5 working days</strong>.
                          </p>
                        </div>
                      </div>

                    </div>

                  </li>
                  <li className="mt-5">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="timeline-left-padding">
                          <h1 className="text-lightgeeen text-white">04</h1>
                          <h5 className="text-white">Escalation</h5>
                          <p>If you are not satisfied with our response, you may contact our Chief Executive Officer at <a href="mailto:ceo@paystreet.io">ceo@paystreet.io</a> for further assistance.<br></br>
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                </div>
              </div>
            </div>
            <p className="text-start">We value your input and are committed to continuous improvement in our service.</p>
          </div>
          
        </section>
        <div className="contact-footer">
          <section className=" IP-page ">
            <div className="scale_business_section">
              <div className="container">
                <div className="row CP-footer contact-subtribe">
                  <div className="col-xs-12">
                    <h2 className="ps-0">
                      Keep up with the <span>latest news</span>
                    </h2>
                    <p className="text-white text-start ps-0">
                      Sign up to receive our latest newsletter straight to your
                      inbox.
                    </p>
                    <div className="input-group banner_input_btn m-0">
                      <input
                        type="text"
                        className="form-control form-manage-subscribe"
                        placeholder="Enter your email"
                        onChange={SubscribtionEmailHandler}
                        name="emailAddress"
                        value={subscribtionMail.emailAddress}
                      />
                      <div className="input-group-append btn-subscribe-append">
                        <button
                          className="btn"
                          type="button"
                          onClick={SubscribtionSubmitEmailHandler}
                          disabled={loading}
                        >
                          {loading ? <PageLoading /> : "Subscribe now"}
                        </button>
                      </div>
                    </div>
                    <div style={{ marginLeft: "1rem" }} className="mt-3">
                      {apiScsMessage && (
                        <p
                          className="text-success"
                          style={{ fontSize: 16, textAlign: "left" }}
                        >
                          {apiScsMessage}{" "}
                        </p>
                      )}
                      {apiErrMessage && (
                        <p
                          className="text-danger"
                          style={{ fontSize: 16, textAlign: "left" }}
                        >
                          {apiErrMessage}{" "}
                        </p>
                      )}
                    </div>

                    {/* {apiMessage && <span className="text-warning">
                      {apiMessage} </span>} */}
                    {/* <div className="input-group mb-4 mt-3">
											<div className="custom-check-ps">
											<div className="custom-control custom-checkbox">
												<input type="checkbox" className="custom-control-input" id="customCheck1"/>
													<label className="custom-control-label" for="customCheck1"><span className="checkbox_p text-white">By submitting the form, you accept PayStreet's <a href="#" className="text-decoration">privacy policy.</a></span></label>
											</div>
											</div>
										</div>
										<div>
											<button type="button" onClick={handleSignup} className="btn contect-submit-btn rounded-pill me-auto">Sign up</button>
										</div> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Contact;
